import axios from "axios";

export async function getIp() {
  try {
    const response = await axios.get("https://freeipapi.com/api/json/", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer d1201543c82f7a38b8e68d72fc5390dbb3def644d15f057d78f87ce5d21eadfe",
      },
    });

    //console.log("COUNTRY", response.data.countryCode);
    return response.data.countryCode;
  } catch (error) {
    //console.error(error);
    return null;
  }
}
