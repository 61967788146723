import axiosInstance from "@/utils/axiosInstance";
import { verifyAndDecodeToken } from "@/utils/jwt";
import axios from "axios";

export async function fetchProducts() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_BASE}/products`, {
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_KEY}`,
    },
    next: {
      // revalidate 1 minute
      revalidate: 30,
    },
  });

  const data = await res.json();

  //console.log("PRODUCTS", data);
  return data || [];
}

export async function fetchProductsByCategoryId(categoryId: string) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL_BASE}/products/category/${categoryId}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_KEY}`,
      },
      next: {
        // revalidate 1 minute
        revalidate: 30,
      },
    }
  );

  const data = await res.json();

  //console.log("PRODUCTS", data);
  return data;
}

export async function fetchProductsByParentCategoryId(categoryId: string) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL_BASE}/products/parentCategory/${categoryId}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_KEY}`,
      },
      next: {
        // revalidate 1 minute
        revalidate: 30,
      },
    }
  );

  const data = await res.json();

  //console.log("PRODUCTS", data);
  return data;
}

export async function fetchProductById(id: string) {
  const res = await axiosInstance.get(`/products/${id}`);
  //console.log("PRODUCT", res.data);
  return res.data;
}

export async function fetchProductBySlug(slug: string) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL_BASE}/products/slug/${slug}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_KEY}`,
      },
      next: {
        // revalidate 1 minute
        revalidate: 0,
      },
    }
  );

  const data = await res.json();
  //console.log("PRODUCT", data);
  return data;
}

export async function finishTbank(
  TBK_TOKEN: string,
  userData: any,
  session: string | undefined
) {
  try {
    console.log("UserID", userData.id);
    console.log("TBK_TOKEN", TBK_TOKEN);
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL_BASE}/payments/finish`,
      {
        token: TBK_TOKEN,
      },
      {
        headers: {
          Authorization: `Bearer ${session}`,
        },
      }
    );
    //console.log(res.data);
    if (res.data.response_code === 0) {
      const lastFourDigits = res.data?.card_number.slice(-4);
      const tbkUser = res.data.tbk_user;
      const tbkCardType = res.data.card_type;

      const data = {
        lastFourDigits,
        tbkUser,
        tbkCardType,
      };

      return data;
    } else {
      throw new Error("Error finishing TBK inscription");
    }
  } catch (err: any) {
    console.log("Error finishing TBK inscription", err);
    return new Error("Error finishing TBK inscription");
  }
}

export async function createPaymentMethod(
  lastfour: string,
  tbk_user: string,
  card_type: string,
  userData: any,
  session: string | undefined
) {
  console.log("Creating payment method");
  console.log("User ID", userData.id);
  console.log("Last four", lastfour);
  console.log("TBK user", tbk_user);
  console.log("Card type", card_type);
  console.log("Username TBK", userData.username);
  try {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL_BASE}/paymentMethods`,
      {
        user_id: userData.id,
        provider_service: "oneclick",
        is_default: true,
        card_type: card_type,
        card_last_number: lastfour,
        provider_customer_id: tbk_user,
        card_token: "none",
        username_tbk: userData?.username,
      },
      {
        headers: {
          Authorization: `Bearer ${session}`,
        },
      }
    );

    console.log("Payment method created", res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    return new Error("Error creating payment method");
  }
}

export async function getUserData(session: string | undefined) {
  try {
    if (!session) {
      const error = new Error("No session found");
      return error;
    }

    const { data, error } = await verifyAndDecodeToken(session);

    if (error) {
      return new Error("Error getting user data");
    }

    return data;
  } catch (err) {
    console.log("Error getting user data", err);
    return new Error("Error getting user data");
  }
}

export async function getRanking(id: number, session: string | undefined) {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/ranking/${id}`,
      {
        headers: {
          Authorization: `Bearer ${session}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    //console.log("Error getting ranking", err);
    return new Error("Error getting ranking");
  }
}
