import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui/ecommerce/cart/CartBtnFixed.tsx");
