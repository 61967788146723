import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface ProductInterface {
  id: number;
  name: string;
  slug: string;
  price: number;
  usd_price?: number;
  discounted_price?: number;
  discounted_usd_price?: number;
  thumbnail_url: string;
  stock: number;
  sku: string;
  type: "single" | "variant" | "subscription" | "virtual";
  category_id: number;
  grams: number;
  variant_id?: number;
}

export interface VariantInfo {
  id: number;
  name: string;
  value: string;
}

interface CartProduct extends ProductInterface {
  qty: number;
  variant?: VariantInfo;
}

interface Coupon {
  id: number;
  code: string;
  discount_type: string;
  discount_amount: number;
  discount_amount_usd: number;
  applicable_products: number[];
}

interface ShippingMethod {
  id: number;
  code: string;
  title: string;
  description: string;
  price: number;
  is_selected: boolean;
}

interface CartState {
  cart: CartProduct[];
  coupon: Coupon | null;
  shippingMethods: ShippingMethod[];
  addToCart: (
    product: ProductInterface,
    quantity: number,
    variant?: VariantInfo
  ) => void;
  removeFromCart: (productId: number, variantId?: number) => void;
  increaseQuantity: (productId: number, variantId?: number) => void;
  decreaseQuantity: (productId: number, variantId?: number) => void;
  clearCart: () => void;
  addCoupon: (coupon: Coupon) => void;
  removeCoupon: () => void;
  setShippingMethods: (methods: ShippingMethod[]) => void;
  selectShippingMethod: (methodId: number) => void;
  clearShippingMethods: () => void;
  trackCartEvent: (eventName: string, eventData?: Record<string, any>) => void;
}

const useCartStore = create<CartState>()(
  persist(
    (set, get) => ({
      cart: [],
      coupon: null,
      shippingMethods: [],

      trackCartEvent: (eventName: string, eventData?: Record<string, any>) => {
        if (typeof window !== "undefined" && (window as any).trackBrevoEvent) {
          const cartData = get().cart.map((item) => ({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.qty,
            variant: item.variant
              ? `${item.variant.name}: ${item.variant.value}`
              : undefined,
            total: item.price * item.qty,
          }));

          const cartTotal = cartData.reduce((sum, item) => sum + item.total, 0);

          const couponData = get().coupon
            ? {
                coupon_code: get().coupon?.code,
                discount_amount: get().coupon?.discount_amount,
              }
            : undefined;

          const eventPayload = {
            cart_items: JSON.stringify(cartData),
            cart_total: cartTotal,
            coupon: couponData ? JSON.stringify(couponData) : undefined,
            ...eventData,
          };

          (window as any).trackBrevoEvent(eventName, eventPayload);
        }
      },

      addToCart: (product, quantity, variant) =>
        set((state) => {
          const existingProductIndex = state.cart.findIndex(
            (item) =>
              item.id === product.id &&
              (product.type !== "variant" || item.variant?.id === variant?.id)
          );

          let newCart;
          if (existingProductIndex > -1) {
            newCart = [...state.cart];
            newCart[existingProductIndex].qty += quantity;
          } else {
            const newProduct: CartProduct = {
              ...product,
              qty: quantity,
              variant: variant,
            };
            newCart = [...state.cart, newProduct];
          }

          get().trackCartEvent("cart_updated", {
            product_id: product.id,
            product_name: product.name,
            quantity: quantity,
            variant: variant ? `${variant.name}: ${variant.value}` : undefined,
          });

          return { cart: newCart };
        }),

      removeFromCart: (productId, variantId) =>
        set((state) => {
          const newCart = state.cart.filter(
            (item) =>
              !(
                item.id === productId &&
                (item.type !== "variant" || item.variant?.id === variantId)
              )
          );

          return { cart: newCart };
        }),

      increaseQuantity: (productId, variantId) =>
        set((state) => {
          const newCart = state.cart.map((item) =>
            item.id === productId &&
            (item.type !== "variant" || item.variant?.id === variantId)
              ? { ...item, qty: item.qty + 1 }
              : item
          );

          return { cart: newCart };
        }),

      decreaseQuantity: (productId, variantId) =>
        set((state) => {
          const newCart = state.cart
            .map((item) =>
              item.id === productId &&
              (item.type !== "variant" || item.variant?.id === variantId)
                ? { ...item, qty: item.qty - 1 }
                : item
            )
            .filter((item) => item.qty > 0);

          return { cart: newCart };
        }),

      clearCart: () => {
        set({ cart: [], coupon: null, shippingMethods: [] });
        get().trackCartEvent("cart_deleted");
      },

      addCoupon: (coupon) => {
        set({ coupon });
      },

      removeCoupon: () => {
        set({ coupon: null });
      },

      setShippingMethods: (methods) => {
        set({ shippingMethods: methods });
      },

      selectShippingMethod: (methodId) =>
        set((state) => {
          const newMethods = state.shippingMethods.map((method) => ({
            ...method,
            is_selected: method.id === methodId,
          }));

          return { shippingMethods: newMethods };
        }),

      clearShippingMethods: () => {
        set({ shippingMethods: [] });
      },
    }),
    {
      name: "persist:cart",
    }
  )
);

export default useCartStore;
